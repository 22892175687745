<template>
  <div class="container">
    <div class="relative">
      <img class="fullWidthSvgDivider above-parent" src="../assets/wave-white-below.svg" alt="Wavy pattern">
      <img class="banner" src="../assets/banner.jpg" alt="Dam in South Dakota at Sunset">
    </div>

    <div class="light text-content-padded relative margin3percent">
      <div class="inside-text-content-padded">
        <div class="inside-text-content-padded">
          <h1>Leif Rasmussen Aerial Photography</h1>
          <br>
          <p>Drone photography and videography serving the Triangle and Triad</p>
          <br>
          <p class="li">Part 107 licensed to fly commercially</p>
          <br>
          <p class="li">Licensed to operate a commercial drone in the State of North Carolina</p>
          <br>
          <p class="li">10 Years Photography Experience, 3 Years Drone Photography Experience</p>
        </div>
      </div>
    </div>

    <img class="fullWidthSvgDivider" src="../assets/wave-yellow-below.svg" alt="white yellow wavy pattern">

    <div class="yellow">
      <div id="real-estate-sidebyside" class="flexbox-img-right-of-text margin3percent">
        <div class="text-content-padded">
          <div class="inside-text-content-padded">
            <h1>Thoughtful Photography</h1>
            <p>I specialize in photography that highlights the best of each location.</p>
          </div>
        </div>
        <div class="spacer"/>
        <div class="text-content-padded">
          <div class="inside-text-content-padded">
            <img class="midWidthImage" alt="Home surrounded by fields and forest" src="../assets/Tibberupgaard.jpg"/>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <ImageGrid :images="imageArray" />
      <div class="margin3percent">
        <div class="text-content-padded">
          <div class="inside-text-content-padded">
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <h2>Quality Above All Else</h2>
            <br>
            <p>By shooting in the perfect lighting, using light and perspective to highlight certain features, and
              framing the subject to make it feel a certain way, I capture pictures that
              show more than just a birds eye view. </p>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <h2>Photography Services</h2>
            <br>
            <h3 class="li">Land Photography</h3>
            <p>Bring out the natural beauty of your land within the surrounding landscape.</p>
            <br>
            <h3 class="li">Home Photography</h3>
            <p>Artfully showcase your home, both inside and out.</p>
            <br>
            <h3 class="li">Event Photography</h3>
            <p>Capture special moments from any perspective.</p>
            <br>
          </div>
        </div>
      </div>
    </div>

    <img class="fullWidthSvgDivider" src="../assets/wave-yellow-above.svg" alt="yellow white wavy pattern">

    <div class="light">
      <div class="flexbox-img-left-of-text margin3percent">
        <div class="text-content-padded">
          <div class="inside-text-content-padded">
            <img class="midWidthImage" alt="Aerial View of Property" src="../assets/orthomosiac.jpg"/>
          </div>
        </div>
        <div class="spacer"/>
        <div class="text-content-padded">
          <div class="inside-text-content-padded">
            <h1>Land Use</h1>
            <p>Get ultra-high-detail maps and 3D models of your property</p>
<!--            <button id="btn-1" @click="toggleExpand1" class="btn-grad">{{ btnText1 }}</button>-->
          </div>
        </div>
      </div>

      <div id="land-use-more-info" class="margin3percent">
        <div v-show="true">
          <div class="text-content-padded">
            <div class="inside-text-content-padded">
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <h2>Orthomosiacs</h2>
              <br>
              <p>An orthomosiac is a combined picture created from a large number of overlapping georeferenced photos
                using photogrammetry software.  The composite image shows each part of the property as it looks from
                directly above, and is spatially referenced.  Orthomosiacs do not have the complexity of a 3D model,
                making them easy to work with.  This is useful for high resolution site plans, property maps, and
                more.</p>
              <br>
              <img class="midWidthImage" alt="Aerial View of Property" src="../assets/gallery010.jpg"/>
              <br>
              <br>
              <br>
              <br>
              <br>
              <br>
              <h2>3D Models</h2>
              <br>
              <p>3D landscape models are useful for creating much more detailed site plans. They can be quickly imported
                into cad software where existing buildings and landscape features such as trees, paths, and open water
                can be integrated with planned changes.  If you're starting a landscaping project, remodeling, or even a
                completely new development, and want to accurately and beautifully visualize how the project will look
                when completed, using a high resolution 3D model is the best way to start.</p>
              <br>
              <img class="midWidthImage" alt="Aerial View of Property" src="../assets/gallery811.jpg"/>

              <!--
              <p>Tap the image below or hover over to see the difference between normal drone photos and orthomosiacs</p>
              <br>
              <br>
              <div class="relative">
                <img src="../assets/compare-perspective.jpg" class="img-under"/>
                <p class="bottom-right white-text">Normal drone photo</p>
                <div id="toggleDiv"
                     class="img-over"
                     :style="{opacity: clickOpacity} "
                     @click="toggleOpacityClick"
                     @mouseenter="toggleOpacityHover"
                     @mouseleave="toggleOpacityHover">
                  <img src="../assets/compare-ortho.jpg" class="img-under"/>
                  <p class="bottom-right white-text">"Straight down" map</p>
                </div>

              </div>
              -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    <img class="fullWidthSvgDivider" src="../assets/wave-lavender-below.svg" alt="white purple wavy pattern">

    <div class="lavender">
      <div class="flexbox-img-right-of-text margin3percent">
        <div class="text-content-padded">
          <div class="inside-text-content-padded">
            <h1>Events</h1>
            <p>Capture your event from a new perspective.</p>
            <button id="btn-2" @click="toggleExpand2" class="btn-grad">{{ btnText2 }}</button>
          </div>
        </div>
        <div class="spacer"/>
        <div class="text-content-padded">
          <div class="inside-text-content-padded">
            <img class="midWidthImage" alt="Hot Air Balloon in Sedona, AZ" src="../assets/SedonaAZ.jpg"/>
          </div>
        </div>
      </div>
      <div id="events-more-info" class="margin3percent">
        <div v-show="isExpanded2">
          <div class="text-content-padded">
            <div class="inside-text-content-padded">
              <p>Nothing here yet.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <img class="fullWidthSvgDivider" src="../assets/wave-lavender-above.svg" alt="purple white wavy pattern">

    <div class="light">
      <div class="flexbox-img-left-of-text margin3percent">
        <div class="text-content-padded">
          <div class="inside-text-content-padded">
            <img class="midWidthImage" alt="Boat in water near Corsica" src="../assets/corsica.jpg"/>
          </div>
        </div>
        <div class="spacer"/>
        <div class="text-content-padded">
          <div class="inside-text-content-padded">
            <h1>Your Project</h1>
            <p>I'm flexible!  Contact me about your project and get a free estimate.</p>
          </div>
        </div>
      </div>
    </div>

    -->

    <div class="vertical-spacer" />
    <img class="bottomPattern" alt="Yellow and Red Wave Pattern" src="../assets/layered-waves-haikei.svg">

    <div class="orange text-content-padded">
      <div class="inside-text-content-padded">
        <div class="contact-info">
          <p class="contact">Leif Rasmussen</p>
          <p class="contact">Email: <a href="mailto:photo@leifbr.com">photo@leifbr.com</a></p>
          <p class="contact">Call/Text: <a href="tel:+19193080031">+1 (919) 308-0031</a></p>
        </div>
      </div>
      <div class="vertical-spacer"/>
    </div>
  </div>
</template>

<script>
import ImageGrid from "@/components/ImageGrid.vue";

export default {
  name: "MainSite",
  components: {ImageGrid},
  data() {
    return {
      clickOpacity: 0,
      isExpanded0: false,
      isExpanded1: false,
      isExpanded2: false,
      btnText0: "SHOW MORE",
      btnText1: "SHOW MORE",
      btnText2: "SHOW MORE",
      imageArray: [
        require("../assets/gallery020.jpg"),
        require("../assets/gallery766.jpg"),
        require("../assets/gallery084.jpg"),
        require("../assets/gallery057.jpg"),
        require("../assets/gallery107.jpg"),
        require("../assets/gallery186.jpg"),
        require("../assets/gallery204.jpg"),
        require("../assets/gallery235.jpg"),
        require("../assets/gallery264.jpg"),
        require("../assets/gallery276.jpg"),
        require("../assets/gallery192.jpg"),
        require("../assets/gallery696.jpg"),
      ],
    }
  },
  methods: {
    toggleExpand0() {
      this.isExpanded0 = !this.isExpanded0;
      if (this.isExpanded0) {
        this.scrollToBottomOf('real-estate-more-info');
      }
      if (this.btnText0 === "SHOW MORE") {
        let deez = this
        setTimeout(function() {
          deez.btnText0 = "SHOW LESS";
        }, 400);
      } else {
        this.btnText0 = "SHOW MORE";
      }

    },
    toggleExpand1() {
      this.isExpanded1 = !this.isExpanded1;
      if (this.isExpanded1) {
        this.scrollToBottomOf('land-use-more-info');
      }
      if (this.btnText1 === "SHOW MORE") {
        let deez = this
        setTimeout(function() {
          deez.btnText1 = "SHOW LESS";
        }, 400);
      } else {
        this.btnText1 = "SHOW MORE";
      }

    },
    toggleExpand2() {
      this.isExpanded2 = !this.isExpanded2;
      if (this.isExpanded2) {
        this.scrollToBottomOf('events-more-info');
      }
      if (this.btnText2 === "SHOW MORE") {
        let deez = this
        setTimeout(function() {
          deez.btnText2 = "SHOW LESS";
        }, 400);
      } else {
        this.btnText2 = "SHOW MORE";
      }

    },
    scrollToBottomOf(divID) {
      const targetDiv = document.getElementById(divID);
      targetDiv.scrollIntoView({behavior: 'smooth', block: 'start'});
    },
    toggleOpacityClick() {
      if (window.innerWidth <= 900) {
        this.clickOpacity = 1 - this.clickOpacity;
      }
    },
    toggleOpacityHover() {
      if (window.innerWidth > 900) {
        this.clickOpacity = 1 - this.clickOpacity;
      }
    },
  }
}
</script>

<style scoped>
html {
  overflow-x: clip;
  scroll-behavior: smooth;
}
.container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.bottom-right {
  position: absolute;
  bottom: 16px;
  right: 32px;
}
.white-text {
  color: white;
  font-size: 2rem;
  font-weight: bold;
  text-shadow: 5px 5px 15px #222222;
}
.banner {
  width:100%;
  max-height:690px;
  min-height:max(330px, 36vw);
  object-fit: cover;
}
.fullWidthSvgDivider {
  user-select: none;
  width: 100%;
  object-fit: cover;
  min-height: 100px;
}
.midWidthImage {
  width: 800px;
  max-width: 100%;
  border-radius: 20px;
}
.bottomPattern {
  user-select: none;
  width: 100%;
  object-fit: cover;
  min-height: 200px;
}

.flexbox-img-left-of-text {
  margin: 10% 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}
.flexbox-img-right-of-text {
  margin: 10% 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: center;
  max-width: 100%;
}
.text-content-padded {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 100%;
}
.margin3percent {
  margin: 3%;
  max-width: 94%;
}
.spacer {
  width: 3vw;
  height: 0;
}
.vertical-spacer {
  height: 3vw;
}
@media (max-width: 900px) {
  .text-content-padded {
    width: 100%;
  }
}

@media (max-width: 1758px) {
  .spacer {
    width: 50vw;
    height: 4vw;
  }
}

.inside-text-content-padded {
  width: 800px;
  max-width: 100%;
  overflow: auto;
}
p, li, button {
  font-size: 1.25rem;
  line-height: 2em
}
h1 {
  font-size: 2rem;
  line-height: 1.5em;
}
.contact {
  margin: 0 20px;

}
.contact-info {
  display: flex;
  flex-direction: row;
  min-width: 100%;
  flex-wrap: wrap;
}

.yellow {
  background-color: #ffe97c;
}
.light {
  background-color: #FFFFFF;
}
.orange {
  background-color: #E48B63;
  color: #FFFFFF;
}
.lavender {
  background-color: #CECEFF;
  color: #000000;
}


.btn-grad {
  background-image: linear-gradient(to right, #f47338 0%, #f99939  51%, #f47338  100%);
  padding: 10px 30px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  display: block;
  border: none;
  cursor: pointer;
}
.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.relative {
  position: relative
}
.above-parent {
  position: absolute;
  bottom: 0;
  z-index: 2;
}
.img-over {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 800px;
  max-width: 100%;
  border-radius: 20px;
  transition:opacity 1.5s;
}

.img-under {
  width: 800px;
  max-width: 100%;
  border-radius: 20px;
}
.li {
  list-style-type: none; /* Remove default bullet point */
  padding-left: 0.5em; /* Add some padding to the left */
}
.li::before {
  content: "\2705"; /* Use Unicode character for check mark */
  color: green; /* Change color to green */
  display: inline-block; /* Make check mark display inline */
  width: 0.5em; /* Set width to match padding */
  margin-left: -0.5em; /* Move check mark left by width of padding */
  padding-right: 1em;
}
</style>