<template>
  <div>
    <div class="image-grid">
      <div v-for="(image, index) in images" :key="index" class="image-container" @click="toggleFullScreen(index)">
        <img :src="image" />
      </div>
    </div>
    <div class="full-screen" :class="{'visible': fullScreen}">
      <img class="whiteCrossTopLeft" src="../assets/white-cross.png" @click="toggleFullScreen()">
      <img class="next" :class="{'disabled': fullScreenIndex === images.length-1}"
           src="../assets/white-right-arrow.png" @click="nextImg()">
      <img class="prev" :class="{'disabled': fullScreenIndex === 0}"
           src="../assets/white-left-arrow.png" @click="prevImg()">
      <img :src="images[fullScreenIndex]" :alt="'Image ' + (fullScreenIndex + 1)" />
    </div>
  </div>
</template>

<script>

export default {
  name: "ImageGrid",
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fullScreen: false,
      fullScreenIndex: null,
    };
  },
  methods: {
    toggleFullScreen(index) {
      if (window.innerWidth >= 800 || this.fullScreen) {
        this.fullScreen = !this.fullScreen;
        this.fullScreenIndex = index;
      }
    },
    nextImg() {
      if (this.fullScreenIndex < this.images.length - 1) {
        this.fullScreenIndex++;
      }
    },
    prevImg() {
      if (this.fullScreenIndex > 0) {
        this.fullScreenIndex--;
      }
    },
    handleKeyDown(event) {
      if (this.fullScreen && event.key === "Escape") {
        this.toggleFullScreen();
      }
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }
};
</script>

<style scoped>
.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(390px, 100%), 1fr));
  grid-gap: min(3vw, 15px);
  margin: min(3vw, 15px);
  max-width: 100%;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
  vertical-align: middle;
  align-content: center;
  justify-content: center;
  max-width: 100%;
  transition: scale 0.5s;
}

.image-container {
  cursor: pointer;
  position: relative;
}

@media (min-width: 800px) {
  .image-container:hover img {
    scale: 1.03;
  }
}

.full-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
}

.full-screen img {
  max-width: 98%;
  max-height: 98%;
  object-fit: contain;
  border-radius: 5px;
  cursor: pointer;
}

.visible {
  opacity: 1;
  pointer-events: auto;
}

.whiteCrossTopLeft {
  position: fixed;
  right: 30px;
  top: 30px;
  width: 50px;
  height: 50px;
}

.next {
  position: fixed;
  right: 30px;
  width: 64px;
  height: 64px;
  top: 50%;
}

.prev {
  position: fixed;
  left: 30px;
  width: 66px;
  height: 66px;
  top: 50%;
}

.disabled {
  opacity: 15%;
}
</style>
